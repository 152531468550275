<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="danger"
          class="btn-sm"
          @click="resetModal(true)"
        >
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="3">
            <label>
              {{ $t("type") }}
            </label>
            <b-form-select
              v-model="filter.type"
              :options="typeFilterOption"
              @change="loadItems"
            />
          </b-col>
          <b-col md="3">
            <label>
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    scope="col"
                    width="200"
                    class="text-center"
                  >
                    {{ $t("type") }}
                  </th>
                  <th
                    width="50"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                  >
                    {{ $t('crt') }}
                  </th>
                  <th width="10" class="text-center">
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="4">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="2">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <span
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.top="item.status? 'Đang hoạt động' : 'Ngưng hoạt động'"
                      variant="outline-primary"
                      style="font-size: 8px"
                      class="mr-1"
                    >
                      <i
                        :class="item.status ? 'text-success' : 'text-danger '"
                        class="fas fa-circle xs"
                      />
                    </span>

                    <span
                      v-b-modal.modal-edit
                      @click="resetModal(false, item)"
                    >{{ item.name }}</span>
                  </td>
                  <td class="text-center">
                    {{ _.find(typeExercise, { 'value': parseInt(item.type) }).label }}
                  </td>
                  <td>{{ convertDate(item.created_at) }}</td>
                  <td class="text-center">
                    <b-dropdown
                      id="dropdown-1"
                      class="custom-position-dropdown"
                      variant="flat-sencondary"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <div
                        v-b-modal.modal-edit
                        class="dropdown-item"
                        @click="resetModal(false, item)"
                      >
                        <feather-icon icon="EditIcon" />
                        {{ $t('edit') }}
                      </div>
                      <div
                        class="dropdown-item"
                        @click="showModalUploadScale(item)"
                      >
                        <feather-icon icon="FileTextIcon" />
                        Upload thang điểm
                      </div>
                      <div
                        class="dropdown-item"
                        @click="deleteMulti(item.id)"
                      >
                        <feather-icon icon="Trash2Icon" />
                        {{ $t('Delete') }}
                      </div>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="8">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
      <b-modal
        v-model="modalUploadScale"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        hide-header
        size="md"
        @ok.prevent="submitUploadScale"
      >
        <validation-observer ref="uploadScale">
          <b-form class="form_custom">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="thang điểm scale"
                    rules="required"
                  >
                    <div>
                      <a
                        :href="endPoint + '/excels/score_rule.xlsx'"
                        class="float-right mb-2"
                      >
                        <feather-icon icon="DownloadIcon" />
                        Tải file mẫu </a>
                      <b-form-file
                        v-model="fileExcelScale"
                        placeholder="Tải file hoặc kéo và thả Excel"
                        accept=".xlsx, .xls, .csv"
                        :state="errors.length > 0 ? false:null"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <b-modal
        id="modal-edit"
        v-model="modalShow"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="lg"
        :title="editModal ? 'Chỉnh sửa' : titleAdd"
        @ok.prevent="submit "
      >
        <validation-observer ref="addSkills">
          <b-form class="form_custom">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('name_q')"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                    <b-form-input
                      id="name"
                      v-model="frm.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('type')"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      {{ $t('type') }} <span class="text-danger">(*)</span></label>
                    <v-select
                      id="type"
                      v-model="frm.type"
                      class=""
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :options="typeExercise"
                      :reduce="label => label.value"
                      :state="errors.length > 0 ? false:null"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('description')"
                  >
                    <div>
                      <label>{{ $t('description') }}</label>
                      <quill-editor
                        v-model="frm.description"
                        :options="snowOption"
                        max-rows="6"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-show="editModal">
              <b-col
                md="5"
                class="text-center"
              >
                <label>Số câu đúng</label>
              </b-col>
              <b-col
                md="5"
                class="text-center"
              >
                <label>Điểm</label>
              </b-col>
            </b-row>
            <b-row :class="editModal ? 'score-rule' : ''">
              <template v-if="editModal">
                <template
                  v-for="(item,index) in frm.score_rule "
                >
                  <b-col md="5">
                    <b-form-group>
                      <label />
                      <input
                        v-model="frm.score_rule[index].number"
                        type="number"
                        class="form-control"
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group>
                      <label />
                      <input
                        v-model="frm.score_rule[index].point"
                        type="number"
                        class="form-control"
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <div
                      class="trash-score-rule"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        style="cursor: pointer"
                        size="16"
                        @click="removeScoreRule(index)"
                      />
                    </div>
                  </b-col>
                </template>
              </template>

              <b-col
                v-else
                md="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="thang điểm"
                    rules="required"
                  >
                    <div>
                      <label :class="errors.length > 0 ? 'text-danger': null">Tải file excel thang điểm <span class="text-danger">(*)</span></label>
                      <a
                        :href="endPoint + '/excels/score_rule.xlsx'"
                        class="float-right mb-2"
                      >
                        <feather-icon icon="DownloadIcon" />
                        Tải file mẫu </a>
                      <b-form-file
                        v-model="fileExcel"
                        placeholder="Tải file hoặc kéo và thả Excel"
                        accept=".xlsx, .xls, .csv"
                        :state="errors.length > 0 ? false:null"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-show="editModal"
              class="mt-2 mb-3 text-center"
            >
              <div
                class="d-inline-flex align-items-center btn btn-primary btn-sm"
                @click="addScoreRule"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="20"
                  class="align-middle"
                  style="cursor: pointer"
                /> Thêm mới
              </div>
            </div>
            <b-row>
              <b-col md="6">
                <div class="form-label-group d-flex">
                  <b-form-checkbox
                    id="status1"
                    v-model="frm.status"
                    class="custom-control-danger"
                    name="is_active"
                    switch
                  />
                  <div>{{ $t('status') }}</div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import config from '@/config'
import vSelect from 'vue-select'
import {
  min_value,
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed, VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BSkeleton,
    BImg,
    BRow,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      typeExercise: config.skill.type,
      modalUploadScale: false,
      typeFilterOption: [
        {
          text: 'Tất cả',
          value: 'all',
        },
        {
          text: 'Kiểm tra đầu vào',
          value: 2,
        },
        {
          text: 'Thi thử miễn phí',
          value: 3,
        },
        {
          text: 'Kiểm tra trình độ',
          value: 4,
        },
        {
          text: 'Kiểm tra định kỳ',
          value: 5,
        },
      ],
      cbxAll: false,
      editModal: false,
      modalShow: false,
      currentPage: 1,
      fileExcel: null,
      skill_id: null,
      fileExcelScale: null,
      frm: {
        name: null,
        description: null,
        status: false,
        type: null,
        score_rule: [],
      },
      pageLength: 10,
      isShow: true,
      dragging: false,
      titleAdd: this.$t('Add'),
      required,
      min_value,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập mô tả kỹ năng',
        modules: {
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
          ],
        },
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
        status: '',
        type: 'all',
      },
      sort: {
        by: 'id',
        direction: 'desc',
      },

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/skills', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    removeScoreRule(index) {
      this.frm.score_rule.splice(index, 1)
    },
    addScoreRule() {
      this.frm.score_rule.push({
        number: '',
        point: '',
      })
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/skills', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          type: this.filter.type,
          status: this.filter.status,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    resetModal(flag, skill = false) {
      if (flag) {
        this.editModal = false
        this.frm.name = this.frm.description = this.frm.type = null
        this.frm.status = false
        this.frm.score_rule = []
      } else {
        this.editModal = skill.id
        this.frm.name = skill.name
        this.frm.description = skill.description
        this.frm.type = skill.type
        this.frm.score_rule = JSON.parse(skill.score_rule)
        this.frm.status = skill.status
      }
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addSkills.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frm.name)
          if (this.frm.description) {
            formData.append('description', this.frm.description)
          }
          formData.append('type', this.frm.type)
          if (this.editModal) {
            formData.append('score_rule', JSON.stringify(this.frm.score_rule))
          } else {
            formData.append('score_rule', this.fileExcel)
          }
          formData.append('status', this.frm.status ? 1 : 0)

          const url = this.editModal ? `/skills/${this.editModal}` : '/skills'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()
              this.resetModal(true)

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log(error)
              // let errorMsg = {}
              // if (error.response.status === 422) {
              //   errorMsg = error.response.data
              // } else {
              //   errorMsg.name = [error.response.data.error]
              // }
              // this.$refs.addQuestion.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    showModalUploadScale(skill) {
      this.modalUploadScale = true
      this.skill_id = skill.id
    },
    submitUploadScale() {
      this.$refs.uploadScale.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          formData.append('id', this.skill_id)
          formData.append('score_rule', this.fileExcelScale)
          const url = '/skills/update-file-scale'
          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalUploadScale = false
              this.loadItems()
              this.skill_id = this.fileExcelScale = null

              this.$bvToast.toast('upload file thang điểm thành công', {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.score-rule{
  max-height:400px;
  overflow-y: scroll;
  justify-content: center;
}
.trash-score-rule {
  cursor: pointer;
  margin-top: 30px;
  justify-items: center;
  display: flex;
  gap: 7px
}
</style>
